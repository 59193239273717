<template>
  <div class="page-content">
    <div v-loading="formLoaded">
      <el-form
        ref="mainForm"
        :model="formData"
        :rules="checkRules"
        label-width="120px"
        label-position="right"
        :style="`width:${proportion ? proportion : 100}%`"
      >
        <el-row>
          <el-col :span="24">
            <!-- <el-form-item label="实践岗位" prop="JobId">
              <el-select v-model="formData.JobId" placeholder="请选择">
                <el-option v-for="item in jobList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="项目班" prop="LayoutId">
              <el-select v-model="formData.LayoutId" placeholder="请选择">
                <el-option
                  v-for="item in projectClassList"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="规则名称" prop="Name">
              <el-input v-model="formData.Name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作日">
              <!-- <SelectListMulti v-model="formData.WorkDayList" :options="weeklist"> </SelectListMulti> -->
              <el-select
                v-model="testWeekList"
                multiple
                placeholder="请选择"
                class="w100"
              >
                <el-option
                  v-for="item in weeklist"
                  :key="item.Value"
                  :label="item.Text"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签到时间" prop="SignInTimeStr">
              <el-time-picker
                @input="handleSignInTimeStr"
                v-model="formData.SignInTimeStr"
                placeholder="请选择时间"
                format="HH:mm"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签退时间" prop="SignOutTimeStr">
              <el-time-picker
                @input="handleSignOutTimeStr"
                v-model="formData.SignOutTimeStr"
                placeholder="请选择时间"
                format="HH:mm"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生效日期" prop="StartDate">
              <!-- <time-selector
                v-model="formData.StartDate"
                display-type="date"
                class="w100"
              ></time-selector> -->
              <el-date-picker
                v-model="formData.StartDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="失效日期" prop="EndDate">
              <!-- <time-selector
                v-model="formData.EndDate"
                display-type="date"
                class="w100"
              ></time-selector> -->
              <el-date-picker
                v-model="formData.EndDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否定位" prop="IsUploadLocation">
              <el-radio-group v-model="formData.IsUploadLocation">
                <el-radio :label="false">否</el-radio>
                <el-radio :label="true">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="formData.IsUploadLocation" :span="12">
            <el-form-item label="定位地点" prop="AddressNames">
              <el-input
                v-model="formData.AddressNames"
                readonly
                placeholder="选择地点"
                suffix-icon="el-icon-map-location"
                @focus="handleSetLocation"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="是否需要拍照" prop="IsTakePhoto">
              <el-radio-group v-model="formData.IsTakePhoto">
                <el-radio :label="false">否</el-radio>
                <el-radio :label="true">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="IsUploadLocation">
              <el-input
                v-model="formData.Remark"
                type="textarea"
                :rows="5"
                placeholder="请输入..."
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="当前状态" prop="Status">
              <el-radio-group v-model="formData.Status">
                <el-radio :label="0">启用</el-radio>
                <el-radio :label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-dialog
        :visible.sync="dialogVisible"
        title="选择定位地点"
        width="900px"
        :before-close="dialogBeforeClose"
      >
        <div class="mapcontainner">
          <!-- 给地图加点击事件getLocationPoint，点击地图获取位置相关的信息，经纬度啥的 -->
          <!-- scroll-wheel-zoom：是否可以用鼠标滚轮控制地图缩放，zoom是视图比例 -->
          <baidu-map
            class="bmView"
            :scroll-wheel-zoom="true"
            :center="location"
            :zoom="zoom"
            ak="YOUR_APP_KEY"
            @ready="handler"
          >
            <bm-view style="width: 600px; height:400px; flex: 1"></bm-view>
            <bm-local-search
              :keyword="keyword"
              :auto-viewport="true"
              :location="location"
            ></bm-local-search>
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
            ></bm-geolocation>
            <!-- <bm-local-search :keyword="addressKeyword" :auto-viewport="true" ></bm-local-search> -->
          </baidu-map>
          <div class="areaForm">
            <div class="area-item">
              <span class="area-text">搜索地区：</span>
              <el-input v-model="location"></el-input>
            </div>
            <div class="area-item">
              <span class="area-text">搜索关键词：</span>
              <el-input v-model="keyword"></el-input>
            </div>
            <div class="area-item">
              <span class="area-text">打卡定位坐标：</span>
              <el-row>
                <el-col :span="11">
                  <el-input
                    v-model="formLocation.Longitude"
                    readonly
                  ></el-input>
                </el-col>
                <el-col :span="2"><span class="span-split">,</span></el-col>
                <el-col :span="11">
                  <el-input v-model="formLocation.Latitude" readonly></el-input>
                </el-col>
              </el-row>
            </div>
            <div class="area-item">
              <span class="area-text">打卡定位地址：</span>
              <el-input v-model="formLocation.Address" readonly></el-input>
            </div>
            <div class="area-item">
              <span class="area-text">打卡有效半径（米）：</span>
              <el-input v-model="formLocation.EffectiveArea"></el-input>
            </div>
            <!-- <el-input v-model="addressKeyword" placeholder="请输入地址来直接查找相关位置"></el-input> -->
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button :loading="dialogSubmitting" @click="dialogVisible = false"
            >取消</el-button
          >
          <el-button
            :loading="dialogSubmitting"
            type="primary"
            @click="handleSubmitForm"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <div class="form-footer">
      <div>
        <el-button
          :loading="isUploading"
          type="primary"
          class="btnBottom"
          @click="submitForm"
          >保存</el-button
        >
        <el-button :loading="isUploading" class="btnBottom" @click="cancle"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { saveSignRule, getInfo, getWeekDays } from "@/api/jobSignRule";
import { getInternshipJobList } from "@/api/jobApply";
import { StaffTypeEnum } from "@/utils/constant";
import contentTools from "@/mixins/content-tools";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmView from "vue-baidu-map/components/map/MapView.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";
import BmGeolocation from "vue-baidu-map/components/controls/Geolocation.vue";

import { getAllList as getProjectClassList } from "@/api/internshipLayout";

const defaultEnterprise = {
  Id: "",
  Name: "",
  Logo: "",
  Introduction: ""
};

export default {
  name: "Detail",
  components: {
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmGeolocation
    // FormGenerator
  },
  provide() {
    return {
      editUpload: this.editUpload
    };
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    }
  },
  mixins: [contentTools],
  data() {
    var validateWorkDay = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("必填项"));
      } else {
        callback();
      }
    };
    return {
      isUploading: false,
      formLoaded: false,
      checkRules: {
        JobId: [{ required: true, message: "必填项", trigger: "blur" }],
        LayoutId: [{ required: true, message: "必填项", trigger: "blur" }],
        Name: [{ required: true, message: "必填项", trigger: "blur" }],
        WorkDayList: [
          { required: true, message: "必填项", trigger: "blur" }
          // validateWorkDay
        ],
        SignInTimeStr: [{ required: true, message: "必填项", trigger: "blur" }],
        SignOutTimeStr: [
          { required: true, message: "必填项", trigger: "blur" }
        ],
        AddressNames: [
          { required: true, message: "必填项", trigger: "change" }
        ],
        StartDate: [{ required: true, message: "必填项", trigger: "blur" }],
        EndDate: [{ required: true, message: "必填项", trigger: "blur" }]
      },
      isShow: false,
      proportion: 100,
      formData: {
        WorkDayList: [],
        SignInTimeStr: "",
        SignOutTimeStr: ""
      },
      dialogVisible: false,
      formEnterprise: {},
      dialogSubmitting: false,
      weeklist: [],
      roleType: null,
      jobList: [],
      projectClassList: [],
      location: "北京",
      keyword: "",
      testWeekList: [],
      // location: {
      //   lng: 116.404,
      //   lat: 39.915
      // },
      zoom: 12.8,
      addressKeyword: "",
      formLocation: {
        Address: "",
        Longitude: "",
        Latitude: "",
        EffectiveArea: 100
      }
    };
  },
  watch: {
    location(val) {
      console.log("nowLocation:", val);
    }
  },
  async created() {
    await this.initialize();
  },
  methods: {
    handleSignInTimeStr(e) {
      this.$set(this.formData, "SignInTimeStr", e);
      this.$forceUpdate();
    },
    handleSignOutTimeStr(e) {
      this.$set(this.formData, "SignOutTimeStr", e);
      this.$forceUpdate();
    },
    async initialize() {
      try {
        this.formLoaded = true;
        if (this.isEdit) {
          await this.getSignRuleInfo();
        }
        // await this.getJobList();
        await this.getProjectClassList();
        await this.getWeekList();
      } catch (err) {
        console.log(err);
      } finally {
        this.formLoaded = false;
      }
    },
    async submitForm() {
      if (this.isUploading) {
        return;
      } else {
        this.isUploading = true;
      }
      var isSuccess = false;
      var msg = "服务器错误！";
      let { SignInTimeStr, SignOutTimeStr } = this.formData;
      var postData = Object.assign({}, this.formData);
      postData.SignInTimeStr = this.formartDate(SignInTimeStr, "h-m-s");
      postData.SignOutTimeStr = this.formartDate(SignOutTimeStr, "h-m-s");

      if (!postData.IsUploadLocation || postData.AddressNames != "") {
        this.$refs.mainForm.clearValidate("AddressNames");
      }

      postData.WorkDay = this.testWeekList.join(",");
      if (this.testWeekList.length < 1) {
        this.$message.error("请选择工作日！");
        this.isUploading = false;
        return false;
      }
      this.$refs.mainForm.validate(async valid => {
        try {
          if (valid) {
            const response = await saveSignRule(postData);
            isSuccess = response.IsSuccess;
            console.log(response);
            if (isSuccess) {
              this.$message.success("保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(`保存失败：${msg}`);
            }
          } else {
            this.$message.error("保存失败：表单填写有误！");
            return false;
          }
        } finally {
          this.isUploading = false;
        }
      });
    },
    async getSignRuleInfo() {
      const res = await getInfo(this.id);
      if (res.IsSuccess) {
        this.formData = res.Result;
        this.testWeekList = this.formData.WorkDay.split(",");
        let { SignInTime, SignOutTime } = this.formData;
        // let signInStr = `${SignInTime.Hours}:${SignInTime.Minutes}:${SignInTime.Seconds}`;
        // let signOutStr = `${SignOutTime.Hours}:${SignOutTime.Minutes}:${SignOutTime.Seconds}`;
        let date = this.formartDate(new Date(), "day");
        this.formData.SignInTimeStr = `${date} ${SignInTime}`;
        this.formData.SignOutTimeStr = `${date} ${SignOutTime}`;
        if (this.formData.IsUploadLocation) {
          this.formLocation = this.formData;
          this.formData.AddressNames = this.formData.RuleLocations[0].Address;
        }
      }
    },
    async getJobList() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        this.roleType = StaffTypeEnum.Enterprise;
      }
      let temp = null;
      let id = null;
      temp = this.$store.getters.employee.find(
        el => el.StaffType === this.roleType
      );
      if (temp) {
        id = temp.Employer.Id;
      } else {
        id = null;
      }
      const res = await getInternshipJobList({ enterpriseId: id });
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    },
    async getProjectClassList() {
      const { Result = [] } = await getProjectClassList();
      this.projectClassList = Result;
    },
    async getWeekList() {
      const res = await getWeekDays();
      if (res.IsSuccess) {
        this.weeklist = res.Result;
      }
    },
    cancle() {
      this.$confirm(
        "系统将不会保存您所更改的内容！",
        "您确定要离开当前页面吗？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          if (this.isSingle) {
            this.$parent.isView = true;
          } else {
            this.$router.go(-1);
            // this.$root.$emit('deleteTag', this.$route, () =>
            //   this.$router.push('/content/data-set/index/' + this.tableId)
            // )
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleSetLocation() {
      this.dialogVisible = true;
      this.formEnterprise = Object.assign({}, defaultEnterprise);
    },
    dialogBeforeClose(done) {
      this.$refs.mainForm.validateField("AddressNames", function(v) {
        debugger;
      });
      done();
    },
    handleSubmitForm() {
      if (!this.formLocation.Longitude && !this.formLocation.Longitude) {
        this.$message.error("请在地图上选取打卡定位地点");
        return;
      } else {
        let { Latitude, Longitude } = this.formLocation;
        this.formLocation.Latitude = Latitude + "";
        this.formLocation.Longitude = Longitude + "";
        this.formData.RuleLocations = [this.formLocation];
        this.dialogVisible = false;
      }
    },
    handler({ BMap, map }) {
      const _this = this; // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(
        function(r) {
          console.log(r);
          _this.center = { lng: r.longitude, lat: r.latitude }; // 设置center属性值
          _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude }; // 自定义覆盖物
          _this.initLocation = true;
          // var mk = new BMap.Marker(r.point)
          // map.addOverlay(mk)
          // map.panTo(r.point)
          console.log("center:", _this.center); // 如果这里直接使用this是不行的
        },
        { enableHighAccuracy: true }
      );
      var marker = new BMap.Marker();
      map.addEventListener("click", function(e) {
        _this.formLocation.Longitude = e.point.lng;
        _this.formLocation.Latitude = e.point.lat;
        var geoc = new BMap.Geocoder();
        geoc.getLocation(e.point, function(rs) {
          var addComp = rs.addressComponents;
          console.log("address", addComp);
          var address =
            addComp.province +
            addComp.city +
            addComp.district +
            addComp.street +
            addComp.streetNumber;
          _this.formLocation.Address = address;
          _this.formData.AddressNames = address;
        });
        console.log(
          "当前位置百度的经度:" + e.point.lng + " , 纬度: " + e.point.lat
        );
        var now_point = new BMap.Point(e.point.lng, e.point.lat);
        marker.setPosition(now_point); // 设置覆盖物位置
        map.addOverlay(marker);
      });
      // 下面注释是百度地图API官方实现方法，因为我使用自定义图标覆盖物，所以没有使用这种方法！
      // 如使用以下这种方法，那么我Template里所写的自定义定位图标代码是不需要的
      // var geolocation = new BMap.Geolocation();
      // geolocation.getCurrentPosition(function(r){
      // 	if(this.getStatus() == BMAP_STATUS_SUCCESS){
      // 		var mk = new BMap.Marker(r.point);
      // 		map.addOverlay(mk);
      // 		map.panTo(r.point);
      // 		alert('您的位置：'+r.point.lng+','+r.point.lat);
      // 	}
      // 	else {
      // 		alert('failed'+this.getStatus());
      // 	}
      // },{enableHighAccuracy: true})
    }
  }
};
</script>

<style lang="scss" scoped>
.mapcontainner {
  display: flex;
  .areaForm {
    width: 100%;
    margin-left: 10px;
    .el-input__inner {
      padding: 0px 5px !important;
    }
    .area-item {
      margin-bottom: 16px;
      .area-text {
        display: flex;
        margin-bottom: 8px;
      }
      .span-split {
        display: flex;
        height: 32px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
